<template>
  <BCard body>
    <div class="d-flex flex-col">
      <div class="d-flex align-items-start mb-[20px]">
        <b-button
          variant="primary"
          size="md"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="font-semibold text-black mb-2 text-2xl">
          Informasi Gudang
        </div>
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <b-row>
          <b-col lg="6">
            <div class="container__img">
              <div class="boxFirst">
                <img
                  ref="imageDisplayOne"
                  :src="imagesone"
                  class="h-100"
                  alt="gudang"
                >
              </div>
              <div class="container__navimg">
                <span
                  v-if="countSlide > 0"
                  class="cursorLeftSlide"
                  @click="handleCursor('left')"
                >
                  <feather-icon
                    size="2x"
                    icon="ChevronLeftIcon"
                  />
                </span>
                <div
                  ref="imgcontainer__childBox"
                  class="container__childBox"
                >
                  <div
                    v-for="(dt, keyImg) in imagesothers"
                    :key="keyImg"
                    :ref="`childOtherbox-${keyImg}`"
                    class="childOtherbox"
                    @click="handleChangeImge(dt, `childOtherbox-${keyImg}`)"
                  >
                    <img
                      :src="dt"
                      style="height: 100%"
                      alt="gudang"
                    >
                  </div>
                </div>
                <span
                  v-if="countSlide < imagesothers.length - 1"
                  class="cursorRightSlide"
                  @click="handleCursor('right')"
                >
                  <feather-icon
                    size="2x"
                    icon="ChevronRightIcon"
                  />
                </span>
              </div>
            </div>
          </b-col>
          <b-col
            v-if="!loading"
            lg="6"
            class="p-x1 mt-1"
          >
            <div
              class="
              d-flex
              align-items-center
              justify-content-between
              containerDetail
            "
            >
              <div class="d-flex align-items-center mb-1">
                <img
                  :src="detailInfo.image_logo_url"
                  class="mr-1 border-radius"
                  style="
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  object-fit: cover;"
                  alt="logo"
                >
                <div class="d-grid">
                  <div class="text-black font-medium">
                    {{ detailInfo.warehouse_name }}
                  </div>
                  <span class="text-gray-500">{{ detailInfo.city_name }}</span>
                </div>
              </div>
            </div>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="text-black font-medium">Status</span>
              </b-col>
              <span class="text-black">: </span>
              <b-col>
                <b-badge
                  :variant="
                    detailInfo.availability === 'Penuh'
                      ? 'light-primary'
                      : 'light-success'
                  "
                >
                  {{ detailInfo.availability }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="text-black font-medium">Jumlah Order</span>
              </b-col>
              <span class="text-black">: </span>
              <b-col>
                <span
                  class="text-black"
                >{{ detailInfo.total_order }} Order Pengiriman</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="text-black font-medium">Owner</span>
              </b-col>
              <span class="text-black">: </span>
              <b-col class="d-flex">
                <span class="text-black mr-1">{{ detailInfo.owner }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="4">
                <span class="text-black font-medium">Mulai Bergabung</span>
              </b-col>
              <span class="text-black">: </span>
              <b-col>
                <span class="text-black">{{ detailInfo.join_date }}</span>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="4">
                <span class="text-black font-medium">Alamat Gudang</span>
              </b-col>
              <span class="text-black">: </span>
              <b-col>
                <span class="text-black">{{ detailInfo.detail_address }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                v-if="detailInfo.subscribe_status === 1"
                lg="6"
                md="6"
                sm="12"
              >
                <b-button
                  variant="outline-primary"
                  class="d-flex justify-content-center align-items-center h-full"
                  block
                  :disabled="loadingCheck"
                  @click="handleUnsub"
                >
                  <b-spinner
                    v-if="loadingCheck"
                    small
                  />
                  <span
                    v-else
                    class="font-bolder"
                  > Berhenti Berlangganan </span>
                </b-button>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-button
                  :variant="detailInfo.availability === 'Penuh' || detailInfo.submission_pending === 1 ? 'secondary' : 'primary'"
                  class="text-center py-1 px-2"
                  :disabled="detailInfo.availability === 'Penuh' || detailInfo.submission_pending === 1"
                  block
                  @click="redirectToSubmission(detailInfo)"
                >
                  <span
                    v-if="detailInfo.subscribe_status === 0"
                    class="font-bolder"
                  >
                    Ajukan Langganan {{ detailInfo.subscribe_count === 0 ? 'Gudang' : 'Ulang' }}
                  </span>
                  <span
                    v-if="detailInfo.subscribe_status === 1"
                    block
                    class="font-bolder"
                  >
                    Cek Stok
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr
          v-if="!loading"
          class="border-gray-300 my-2"
        >

        <div
          v-if="!loading"
          class="d-grid"
        >
          <div class="text-black font-semibold mb-1">
            Deskripsi
          </div>
          <span class="text-black mt-1">{{ detailInfo.description }}</span>
        </div>
      </BOverlay>
    </div>
  </BCard>
</template>

<script>
import {
  BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  data() {
    return {
      imagesone: '',
      imagesothers: [],
      isLoadingPage: true,
      detailInfo: {},
      countSlide: 0,

      loading: false,

      loadingCheck: false,

      partnerProductTotal: null,
    }
  },
  watch: {
    countSlide: {
      handler(newVal, oldVal) {
        this.imagesone = this.imagesothers[newVal]
        this.$refs[`childOtherbox-${oldVal}`][0].classList.remove(
          'borderActive',
        )
        this.$refs[`childOtherbox-${newVal}`][0].classList.toggle(
          'borderActive',
        )
        if (oldVal > newVal) {
          this.$refs.imgcontainer__childBox.scrollLeft -= 100
        } else {
          this.$refs.imgcontainer__childBox.scrollLeft += 100
        }
      },
    },
  },
  mounted() {
    this.fetchData()
    this.checkProduct()
  },
  methods: {
    handleChangeImge(dt, refKey) {
      this.imagesone = dt
      const [indexData] = refKey.split('-')
      this.countSlide = parseInt(indexData, 10)
    },

    async checkProduct() {
      await this.$http_komship.get('/v1/komship/submission/count-product')
        .then(res => {
          const { data } = res.data
          this.partnerProductTotal = data.total
        }).catch(err => {
          console.log(err)
        })
    },

    redirectToSubmission(data) {
      if (this.partnerProductTotal === 0) {
        this.$swal({
          title: 'Tambahkan Produk',
          text: 'Kamu belum memiliki data produk, yuk tambahkan produk terlebih dahulu!',
          icon: 'warning',
          width: '50%',
          iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
          showCancelButton: true,
          cancelButtonText: 'Tidak',
          confirmButtonText: 'Tambahkan',
          reverseButtons: true,
          customClass: {
            icon: 'border-0 w-50 my-5',
            confirmButton: 'btn btn-primary px-4',
            cancelButton: 'btn btn-outline-primary mr-1 px-5',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$router.push({
              path: '/add-produk',
            })
          }
        })
      } else {
        const { subscribe_status, id } = data
        // eslint-disable-next-line camelcase
        if (subscribe_status === 0) {
          this.$router.push({
            path: `/search-gudang/detail/submission/${this.$route.params.id}`,
          })
          localStorage.setItem('warehouse_id', id)
        // eslint-disable-next-line camelcase
        } if (subscribe_status === 1) {
          this.$router.push({
            path: `/detail-gudang-kompack/${this.$route.params.id}`,
          })
        }
      }
    },
    handleCursor(type) {
      switch (type) {
        case 'left':
          if (this.countSlide === 0) {
            //
          } else {
            this.countSlide -= 1
          }
          break
        case 'right':
          if (this.countSlide === this.imagesothers.length - 1) {
            //
          } else {
            this.countSlide += 1
          }
          break
        default:
          break
      }
    },
    handleEditData() {
      this.$router.push({
        name: 'kompack-rincian-mitra-gudang',
        params: {
          id: this.$route.params.id,
        },
        query: {
          isEditMode: true,
        },
      })
    },
    fetchData() {
      this.loading = true
      this.$http_new.get(
        `/komship/api/v1/warehouses/${this.$route.params.id}`,
      )
        .then(({ data }) => {
          this.loading = false
          this.detailInfo = data.data

          this.imagesone = data.data.image_warehouse[0].image_url
          this.imagesothers = [...data.data.image_warehouse]
            .splice(0, 8)
            .map(x => x.image_url)
          this.$nextTick(() => {
            this.isLoadingPage = false
          })
        })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    handleUnsub() {
      this.loadingCheck = true
      this.$http_komship.get(
        `/v1/komship/warehouse/unsubscribe-elgibility/${this.$route.params.id}`,
      )
        .then(res => {
          this.loadingCheck = false
          const { data } = res.data
          if (!data.an_order && !data.an_outbound && !data.in_stock) {
            this.$swal({
              title: 'Apakah kamu yakin untuk berhenti berlangganan?',
              text: 'Setelah kamu berhenti berlangganan orderanmu sudah tidak dilayanani gudang ini lagi',
              icon: 'warning',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              showCancelButton: true,
              cancelButtonText: 'Tidak',
              confirmButtonText: 'Ya',
              customClass: {
                icon: 'border-0 w-25',
                confirmButton: 'btn btn-outline-primary mr-1 px-5',
                cancelButton: 'btn btn-primary px-4',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.unsubscribe()
              }
            })
          } else if (data.an_order && !data.an_outbound && !data.in_stock) {
            const html = `
                <div style="margin-top: 4px;">
                  <div>Kamu tidak bisa berhenti berlangganan gudang karena kamu masih memiliki orderan yang belum diselesaikan. cek data ordermu yang belum selesai 
                  di data order yaa..</div>
                </div>
              `
            this.$swal({
              title: 'Tidak bisa berhenti berlangganan',
              html,
              width: '500px',
              icon: 'warning',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              showCancelButton: true,
              showConfirmButton: true,
              reverseButtons: true,
              cancelButtonText: 'Batal',
              confirmButtonText: 'Cek Order',
              customClass: {
                icon: 'border-0 w-25',
                cancelButton: 'btn btn-outline-primary mr-1',
                confirmButton: 'btn btn-primary ',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  path: '/data-order',
                })
              }
            })
          } else if (!data.an_order && !data.an_outbound && data.in_stock) {
            const html = `
                <div style="margin-top: 4px;">
                  <div>Kamu tidak bisa berhenti berlangganan gudang karena kamu masih memiliki stok produk yang tersimpan di gudang.
Untuk mengajukan berhenti berlangganan gudang, 
kamu harus melakukan pengeluaran barang
 terlebih dahulu ya...</div>
                </div>
              `
            this.$swal({
              title: 'Tidak bisa berhenti berlangganan',
              html,
              width: '500px',
              icon: 'warning',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              showCancelButton: true,
              showConfirmButton: true,
              reverseButtons: true,
              cancelButtonText: 'Batal',
              confirmButtonText: 'Keluarkan Barang',
              customClass: {
                icon: 'border-0 w-25',
                cancelButton: 'btn btn-outline-primary mr-1',
                confirmButton: 'btn btn-primary ',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  path: '/ajukan-penarikan-barang/',
                })
              }
            })
          } else if (!data.an_order && data.an_outbound && !data.in_stock) {
            const html = `
                <div style="margin-top: 4px;">
                  <div>Kamu tidak bisa berhenti berlangganan karena masih memiliki antrian penarikan barang</div>
                </div>
              `
            this.$swal({
              title: 'Tidak bisa berhenti berlangganan',
              html,
              width: '500px',
              icon: 'warning',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              showCancelButton: true,
              showConfirmButton: true,
              reverseButtons: true,
              cancelButtonText: 'Batal',
              confirmButtonText: 'Cek Antrian',
              customClass: {
                icon: 'border-0 w-25',
                cancelButton: 'btn btn-outline-primary mr-1',
                confirmButton: 'btn btn-primary ',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({
                  path: '/penarikan-barang',
                })
              }
            })
          } else {
            const html = `
                <div style="margin-top: 4px;">
                  <b>Selesaikan proses-proses berikut agar dapat berhenti berlangganan:</b>
                  <ol style="margin-top: 4px;">
                    <li>- Keluarkan stok produk di gudang.</li>
                    <li>- Selesaikan proses order di gudang.</li>
                    <li>- Selesaikan proses antrian penarikan barang.</li>
                  </ol>
                </div>
              `
            this.$swal({
              title: 'Tidak bisa berhenti berlangganan',
              html,
              width: '500px',
              icon: 'warning',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Oke',
              customClass: {
                icon: 'border-0 w-25',
                cancelButton: 'btn btn-primary w-100',
              },
              buttonsStyling: false,
            }).then(result => {
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    unsubscribe() {
      this.$http_komship.post('/v1/komship/submission/unsubscribe', {
        warehouse_id: this.detailInfo.id,
      }).then(res => {
        const html = `
                <div style="margin-top: 4px;">
                  <div>Terimakasih telah menggunakan gudang Kompack ${this.detailInfo.warehouse_name} - ${this.detailInfo.city_name}. Untuk melayani kebutuhanmu, yuk berlangganan gudang Kompack yang lainnya. Kamu bisa melakukan pengajuan berlangganan gudang kembali 
ke gudang lain di menu cari gudang ya..</div>
                </div>
              `
        this.$swal({
          title: 'Pengajuan berhenti berlangganan sukses',
          html,
          width: '500px',
          icon: 'success',
          iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Cari Gudang',
          customClass: {
            icon: 'border-0 w-25',
            confirmButton: 'btn btn-primary w-100',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(result => {
          if (result.value) {
            this.$router.push({
              path: '/search-gudang',
            })
          }
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            text: 'Coba berhenti berlangganan gudang lagi',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container__img {
  margin-top: 1em;
  display: grid;
  justify-content: center;
  grid-row-gap: 1em;
}
.boxFirst {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 450px;
  height: 300px;

  & > img {
    border-radius: 8px;
  }
}
.container__navimg {
  display: flex;
  align-items: center;
  position: relative;
}
.container__childBox {
  display: -webkit-box;
  width: 450px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.childOtherbox {
  margin-right: 10px;
  object-fit: cover;
  border: 1px solid black;
  border-radius: 8px;
  width: 80px;
  height: 80px;
}
.childOtherbox > img {
  border-radius: 8px;
}
.borderActive {
  border-width: 3px;
}
.cursorLeftSlide {
  cursor: pointer;
  position: absolute;
  left: -35px;
}
.cursorRightSlide {
  cursor: pointer;
  position: absolute;
  right: -30px;
}
*,
img {
  user-select: none;
}
@media only screen and (max-width: 500px) {
  .container__childBox {
    width: 85vw;
  }
  .cursorLeftSlide {
    left: 0px;
  }
  .cursorRightSlide {
    right: 0px;
  }
  .boxFirst {
    width: 100%;
  }
  .containerDetail {
    display: grid !important;
    margin-bottom: 1em;
    width: 135px;
  }
}
</style>
